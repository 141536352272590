import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import FormContainer from 'components/LoanForm/FormContainer';
import Button from 'components/Button';
import { FlowComponentType } from 'routes/FlowRouter';
import { ReactComponent as SignIcon } from 'images/sign-icon.svg';
import { getStudentLoanInnerApplicationData as getStudentLoanApplicationThunk } from 'thunks';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { getStudentLoanData } from 'selectors/getStudentLoanData';
import { getStudentLoanInnerApplicationData } from 'selectors/getStudentLoanApplication';

import { ApplyFirstTimeFlow, ApplyReturningFlow, useApplySteps } from './applySteps';

import styles from './ApplyStepsIntro.module.scss';

const ApplyStepsIntro = ({ handleNext }: FlowComponentType) => {
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const { parentApplicationId } = useSelector(getStudentLoanData);
  const { fetched, isLoading } = useSelector(getStudentLoanInnerApplicationData);
  const [clickedNext, setClickedNext] = useState(false);

  const { stepsStarted } = useApplySteps();

  // The following code ensures that the application is loaded before advancing to the next step.
  useEffect(() => {
    parentApplicationId &&
      (async () => {
        await dispatchWithUnwrap(getStudentLoanApplicationThunk({ applicationId: parentApplicationId }));
      })();
  }, []);

  useEffect(() => {
    if (fetched && clickedNext) {
      handleNext();
    }
  }, [isLoading, clickedNext]);

  const onNext = () => {
    setClickedNext(true);
  };

  return (
    <div className={styles.container}>
      <FormContainer
        icon={<SignIcon />}
        title="Apply with Us"
        subtitle="Our advisors are about to get to work on your application. We need a few things from you."
      >
        {stepsStarted ? <ApplyReturningFlow /> : <ApplyFirstTimeFlow />}
        <Button className={styles.button} onClick={onNext} isLoading={clickedNext && isLoading}>
          Next
        </Button>
      </FormContainer>
    </div>
  );
};

export default ApplyStepsIntro;
