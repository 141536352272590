import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  KnownEmployerProfitStatus,
  PayoffData,
  StudentLoanAssistanceApplyResult,
  StudentLoanAssistanceData,
} from 'api/StudentLoanApi';
import {
  applyWithApplicationToStudentLoan,
  getApplicationData,
  getLoanOffer,
  getStudentLoanAssistanceData,
  updateStudentLoanAssistanceData,
} from 'thunks';

import { GetApplicationDataResponse } from './applicationData';
import { LoanOfferResponse } from './loanOffer';

interface StudentLoanDataFetchStatus {
  isLoading: boolean;
  error: boolean;
  fetched: boolean;
  refetch: boolean;
}
export interface StudentLoanData {
  parentApplicationId?: string;
  applicationData: StudentLoanAssistanceApplyResult & StudentLoanDataFetchStatus;
  assistanceData: {
    eligible?: boolean;
    paymentStartDate?: string;
    nonProfitStartDate?: string | null;
    knownEmployerProfitStatus?: KnownEmployerProfitStatus;
    allLoansMohela?: boolean;
    hasMonthlyPayments?: boolean;
    maxTerm?: number;
    totalBalance?: number;
    monthSavings?: number;
    currentPath?: PayoffData;
    recommendedPath?: PayoffData;
    householdSize?: number;
    undergraduateLoansPercentage?: number;
    enrolledIncomeDrivenRepayment?: boolean;
  } & StudentLoanDataFetchStatus;
}

export type StudentLoanDataState = StudentLoanData;

const initialState: StudentLoanDataState = {
  applicationData: {
    isLoading: false,
    error: false,
    fetched: false,
    refetch: false,
  },
  assistanceData: {
    isLoading: false,
    error: false,
    fetched: false,
    refetch: false,
  },
};

const updateState = (state: StudentLoanDataState, { payload }: PayloadAction<StudentLoanAssistanceData>) => {
  state.assistanceData = {
    ...state.assistanceData,
    fetched: true,
    isLoading: false,
    knownEmployerProfitStatus: payload.knownEmployerProfitStatus,
    eligible: payload.eligible,
    paymentStartDate: payload.paymentStartDate,
    nonProfitStartDate: payload.nonProfitStartDate,
    maxTerm: payload.maxTerm,
    totalBalance: payload.totalBalance,
    monthSavings: payload.monthSavings,
    currentPath: payload.currentPath,
    recommendedPath: payload.recommendedPath,
    householdSize: payload.householdSize,
    undergraduateLoansPercentage: payload.undergraduateLoansPercentage,
    enrolledIncomeDrivenRepayment: payload.enrolledIncomeDrivenRepayment,
    allLoansMohela: payload.allLoansMohela,
    hasMonthlyPayments: payload.hasMonthlyPayments,
  };
};

const auth = createSlice({
  name: 'studentLoanData',
  initialState,
  reducers: {
    setForProfit: (state: StudentLoanData, { payload }: PayloadAction<boolean>) => {
      const knownEmployerProfitStatus = payload
        ? KnownEmployerProfitStatus.ForProfit
        : KnownEmployerProfitStatus.NonProfit;
      state.assistanceData = {
        ...state.assistanceData,
        knownEmployerProfitStatus,
      };
    },
    setStudentLoanApplicationId: (state: StudentLoanData, { payload }: PayloadAction<string>) => {
      state.parentApplicationId = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getStudentLoanAssistanceData.pending, (state, action) => {
      state.parentApplicationId = action.meta.arg.applicationId;
      state.assistanceData = {
        ...state.assistanceData,
        refetch: (state.assistanceData as StudentLoanDataFetchStatus).fetched,
        isLoading: true,
        fetched: false,
      };
    });
    builder.addCase(getStudentLoanAssistanceData.fulfilled, updateState);
    builder.addCase(getStudentLoanAssistanceData.rejected, (state) => {
      state.assistanceData = {
        ...state.assistanceData,
        isLoading: false,
        error: true,
        fetched: false,
        eligible: false,
      };
    });
    builder.addCase(updateStudentLoanAssistanceData.pending, (state, action) => {
      state.parentApplicationId = action.meta.arg.applicationId;
      state.assistanceData = {
        ...state.assistanceData,
        refetch: (state.assistanceData as StudentLoanDataFetchStatus).fetched,
        isLoading: true,
        fetched: false,
      };
    });
    builder.addCase(updateStudentLoanAssistanceData.fulfilled, updateState);
    builder.addCase(updateStudentLoanAssistanceData.rejected, (state) => {
      state.assistanceData = {
        ...state.assistanceData,
        isLoading: false,
      };
    });
    builder.addCase(getApplicationData.fulfilled, (state, { payload }: PayloadAction<GetApplicationDataResponse>) => {
      state.parentApplicationId = payload.application.id;
    });
    builder.addCase(getLoanOffer.fulfilled, (state, { payload }: PayloadAction<LoanOfferResponse>) => {
      if (payload.data.application_id) {
        state.parentApplicationId = payload.data.application_id;
      }
    });
    builder.addCase(applyWithApplicationToStudentLoan.pending, (state) => {
      state.applicationData = {
        ...state.applicationData,
        refetch: (state.applicationData as StudentLoanDataFetchStatus).fetched,
        isLoading: true,
        fetched: false,
      };
    });
    builder.addCase(
      applyWithApplicationToStudentLoan.fulfilled,
      (state, { payload }: PayloadAction<StudentLoanAssistanceApplyResult>) => {
        state.applicationData = {
          ...state.applicationData,
          ...payload,
        };
      },
    );
    builder.addCase(applyWithApplicationToStudentLoan.rejected, (state) => {
      state.applicationData = {
        ...state.applicationData,
        isLoading: false,
        error: true,
      };
    });
  },
});

export const { setForProfit, setStudentLoanApplicationId } = auth.actions;

export default auth.reducer;
