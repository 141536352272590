import { createAsyncThunk } from '@reduxjs/toolkit';
import { StudentLoanApi } from 'api/StudentLoanApi';

export interface GetStudentLoanInnerApplicationData {
  applicationId: string;
}

export const createGetStudentLoanInnerApplicationDataThunk = (api: StudentLoanApi) =>
  createAsyncThunk('studentLoan/getApplication', async (data: GetStudentLoanInnerApplicationData, thunkApi) => {
    try {
      return await api.getApplication(data.applicationId);
    } catch (error: any) {
      thunkApi.rejectWithValue(error);

      throw error;
    }
  });
