import { useSelector } from 'react-redux';

import { ReactComponent as ReferencesIcon } from 'images/step-icons/references-solid.svg';
import { ReactComponent as BriefCaseIcon } from 'images/step-icons/brief-case-solid.svg';
import { ReactComponent as LicenseIcon } from 'images/step-icons/license-solid.svg';
import { ReactComponent as PaystubsIcon } from 'images/step-icons/paystubs-solid.svg';
import { ReactComponent as LoanIcon } from 'images/step-icons/bank-account-solid.svg';
import { FlowComponentType } from 'routes/FlowRouter';
import { StepsNavigation } from 'components/Steps/StepsNavigation/StepsNavigation';
import { useSteps, StepData, UseStepsReturnType } from 'components/Steps/stepUtils';
import FirstTimeFlow from 'components/Steps/StepsFlow/FirstTimeFlow';
import ReturningFlow from 'components/Steps/StepsFlow/ReturningFlow';
import { RoutePath } from 'enums/Routes';
import { getStudentLoanInnerApplicationData } from 'selectors/getStudentLoanApplication';
import { ApplyProgress, ApplyStep, defaultApplyProgress } from 'api/StudentLoanApi';
import { completeStudentLoanApplyStep, setCurrentStudentLoanApplyStep } from 'handlers/studentLoanInnerApplicationData';

import EmploymentDetailsStep from './Steps/EmploymentDetails';
import PersonalDetailsStep from './Steps/PersonalDetails';
import ReferencesStep from './Steps/References';
import UploadIncomeVerificationStep from './Steps/UploadIncomeVerification';
import UploadLoanInformationStep from './Steps/UploadLoanInformation';

export const applySteps: Readonly<StepData<ApplyStep>[]> = Object.freeze([
  {
    id: ApplyStep.PersonalDetails,
    name: 'Personal Details',
    component: PersonalDetailsStep,
    icon: LicenseIcon,
  },
  {
    id: ApplyStep.EmploymentDetails,
    name: 'Employment Details',
    component: EmploymentDetailsStep,
    icon: BriefCaseIcon,
  },
  {
    id: ApplyStep.References,
    name: 'References',
    component: ReferencesStep,
    icon: ReferencesIcon,
  },
  {
    id: ApplyStep.UploadIncomeVerification,
    name: 'Income Verification',
    component: UploadIncomeVerificationStep,
    icon: PaystubsIcon,
  },
  {
    id: ApplyStep.UploadLoanInformation,
    name: 'Loan Information',
    component: UploadLoanInformationStep,
    icon: LoanIcon,
  },
]);

export const useApplySteps = (): UseStepsReturnType<ApplyStep, ApplyProgress> =>
  useSteps(
    useSelector(getStudentLoanInnerApplicationData).currentApplyStep ?? applySteps[0].id,
    setCurrentStudentLoanApplyStep,
    completeStudentLoanApplyStep,
    applySteps,
    useSelector(getStudentLoanInnerApplicationData).applicationData.applyProgress ?? defaultApplyProgress,
    RoutePath.StudentLoanApplyStepsCompleted,
  );

export const ApplyNavigation = (args: FlowComponentType) =>
  StepsNavigation<ApplyStep, ApplyProgress>(args, useApplySteps);
export const ApplyFirstTimeFlow = () => FirstTimeFlow<ApplyStep>(applySteps);
export const ApplyReturningFlow = () =>
  ReturningFlow<ApplyStep, ApplyProgress>(useApplySteps, RoutePath.StudentLoanApplySteps);
