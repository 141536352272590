import React, { useState } from 'react';
import FormNavigation from 'components/FormNavigation';
import { FlowComponentType } from 'routes/FlowRouter';
import Button from 'components/Button';
import NumberInput from 'components/NumberInput';
import { useSelector } from 'react-redux';
import { updateStudentLoanAssistanceData } from 'thunks';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { getStudentLoanData } from 'selectors/getStudentLoanData';
import StudentLoanLoaderWrapper from 'components/StudentLoanForgiveness/LoaderWrapper';

import QuestionsWrapper from './Wrapper/QuestionsWrapper';

import styles from './Questions.module.scss';

const DependentsQuantity = ({ flags, navigationInfo, handleNext: onNext }: FlowComponentType) => {
  const dispatchWithUnwrap = useDispatchWithUnwrap();

  const {
    parentApplicationId: applicationId,
    assistanceData: { householdSize },
  } = useSelector(getStudentLoanData);
  const [dependentsQuantity, setDependentsQuantity] = useState<number | undefined>((householdSize ?? 1) - 1);
  const [isLoading, setIsLoading] = useState(false);

  const handleNext = async () => {
    setIsLoading(true);
    analytics.track('Dependents quantity submited');
    await dispatchWithUnwrap(
      updateStudentLoanAssistanceData({
        applicationId: applicationId!,
        householdSize: (dependentsQuantity ?? 0) + 1,
      }),
    );
    setIsLoading(false);
    onNext();
  };

  return (
    <StudentLoanLoaderWrapper flags={flags}>
      <>
        <FormNavigation {...navigationInfo} />
        <QuestionsWrapper>
          <>
            <NumberInput
              label="How many dependents do you claim on your taxes?"
              placeholder="0"
              onChange={(event) => {
                const value = Number(event.target.value);
                setDependentsQuantity(value);
              }}
              errorMessage={(dependentsQuantity ?? 0) < 0 ? 'You must have 0 or more dependents.' : undefined}
              value={dependentsQuantity ? dependentsQuantity.toString() : '0'}
              name="Dependents Quantity"
            />

            <Button
              disabled={Boolean(dependentsQuantity && dependentsQuantity < 0)}
              onClick={handleNext}
              isLoading={isLoading}
              className={styles.button}
            >
              Next
            </Button>
          </>
        </QuestionsWrapper>
      </>
    </StudentLoanLoaderWrapper>
  );
};

export default DependentsQuantity;
