import { createAsyncThunk } from '@reduxjs/toolkit';
import { StudentLoanApi } from 'api/StudentLoanApi';

export const createApplyWithApplicationToStudentLoanThunk = (api: StudentLoanApi) =>
  createAsyncThunk('studentLoan/applyWithApplication', async (applicationId: string, thunkApi) => {
    try {
      const result = await api.applyWithApplication(applicationId);

      return result;
    } catch (error: any) {
      thunkApi.rejectWithValue(error.response.data);

      throw error;
    }
  });
