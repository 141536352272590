import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApplicationData, ApplyStep, defaultApplyProgress, UploadStudentAidDataResponse } from 'api/StudentLoanApi';
import {
  getApplicationData,
  getLoanOffer,
  getStudentLoanInnerApplicationData,
  updateStudentLoanApplication,
  uploadDocuments,
  uploadStudentAidData,
} from 'thunks';
import { UploadDocumentsResponse } from 'api/DocumentsApi';

import { GetApplicationDataResponse } from './applicationData';
import { LoanOfferResponse } from './loanOffer';

export interface StudentLoanDataState {
  isLoading: boolean;
  error: boolean;
  fetched: boolean;
  refetch: boolean;
  applicationData: ApplicationData;
  currentApplyStep: ApplyStep;
  applicationId?: string;
}

const initialState: StudentLoanDataState = {
  isLoading: false,
  error: false,
  fetched: false,
  refetch: false,
  currentApplyStep: Object.values(ApplyStep)[0],
  applicationData: { applyProgress: defaultApplyProgress },
};

const updateState = (state: StudentLoanDataState, { payload }: PayloadAction<ApplicationData>) => {
  state.isLoading = false;
  state.fetched = true;
  state.applicationData = payload;
};

const studentLoanInnerApplicationData = createSlice({
  name: 'studentLoanInnerApplicationData',
  initialState,
  reducers: {
    setCurrentStudentLoanApplyStep: (state, { payload }: PayloadAction<ApplyStep>) => {
      state.currentApplyStep = payload;
    },
    completeStudentLoanApplyStep: (state, { payload }: PayloadAction<ApplyStep>) => {
      state.applicationData.applyProgress![payload] = true;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getStudentLoanInnerApplicationData.pending, (state, action) => {
      state.applicationId = action.meta.arg.applicationId;
      state.refetch = state.fetched;
      state.isLoading = true;
      state.fetched = false;
    });
    builder.addCase(getStudentLoanInnerApplicationData.fulfilled, updateState);
    builder.addCase(getStudentLoanInnerApplicationData.rejected, (state) => {
      state.isLoading = false;
      state.error = true;
      state.fetched = false;
    });
    builder.addCase(updateStudentLoanApplication.pending, (state, action) => {
      state.applicationId = action.meta.arg.applicationId;
      state.refetch = state.fetched;
      state.isLoading = true;
      state.fetched = false;
    });
    builder.addCase(updateStudentLoanApplication.fulfilled, updateState);
    builder.addCase(updateStudentLoanApplication.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getApplicationData.fulfilled, (state, { payload }: PayloadAction<GetApplicationDataResponse>) => {
      state.applicationId = payload.application.id;
    });
    builder.addCase(getLoanOffer.fulfilled, (state, { payload }: PayloadAction<LoanOfferResponse>) => {
      if (payload.data.application_id) {
        state.applicationId = payload.data.application_id;
      }
    });
    builder.addCase(uploadDocuments.fulfilled, (state, { payload }: PayloadAction<UploadDocumentsResponse>) => {
      state.applicationData && (state.applicationData.applyProgress = payload.studentLoanApplyProgress);
    });
    builder.addCase(
      uploadStudentAidData.fulfilled,
      (state, { payload }: PayloadAction<UploadStudentAidDataResponse>) => {
        state.applicationData && (state.applicationData.applyProgress = payload.applyProgress);
      },
    );
  },
});

export const { setCurrentStudentLoanApplyStep, completeStudentLoanApplyStep } = studentLoanInnerApplicationData.actions;

export default studentLoanInnerApplicationData.reducer;
