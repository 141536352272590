import React, { useEffect, useState } from 'react';

import Button from 'components/Button';
import { StepComponent } from 'components/Steps/stepUtils';
import FormContainer from 'components/LoanForm/FormContainer';
import AddressFields, { Address } from 'components/AddressFields/AddressFields';
import Input from 'components/Input';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { useSelector } from 'react-redux';
import { StepsResult } from 'enums/FlowNextResults';
import { useForm } from 'react-hook-form';
import { updateStudentLoanApplication } from 'thunks';
import { getMessageForInvalidFields, getMessageForRequiredFields } from 'utils/errors';
import { getStudentLoanInnerApplicationData } from 'selectors/getStudentLoanApplication';
import PhoneNumberInput from 'components/PhoneNumberInput';
import { PHONE_NUMBER_LENGTH } from 'components/LoanForm/YourContact/YourContact';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import { PersonalReference, Relationship } from 'api/StudentLoanApi';

import InputSelect from 'components/InputSelect';

import { RELATIONSHIP_OPTIONS } from 'components/StudentLoanForgiveness/Apply/Steps/References/relationshipOptions';

import styles from './References.module.scss';

enum Field {
  FirstName1 = 'Reference 1 first name',
  FirstName2 = 'Reference 2 first name',
  LastName1 = 'Reference 1 last name',
  LastName2 = 'Reference 2 last name',
  Relationship1 = 'Reference 1 relationship',
  Relationship2 = 'Reference 2 relationship',
  Phone1 = 'Reference 1 phone',
  Phone2 = 'Reference 2 phone',
}

enum FieldLabels {
  FirstName = 'First name',
  LastName = 'Last name',
  Phone = 'Phone number',
  RelationshipLabel = 'Relationship',
}

const References = ({ handleNext }: StepComponent) => {
  const dispatchWithUnwrap = useDispatchWithUnwrap();

  const {
    applicationId,
    applicationData: { reference1, reference2 },
  } = useSelector(getStudentLoanInnerApplicationData);

  const [isLoading, setIsLoading] = useState(false);
  const [address1, setAddress1] = useState<Address>(getAddress(reference1));
  const [address2, setAddress2] = useState<Address>(getAddress(reference2));
  const [isAddress1Valid, setIsAddress1Valid] = useState(isCompleteAddress(address1));
  const [isAddress2Valid, setIsAddress2Valid] = useState(isCompleteAddress(address2));
  const [phoneNumberIsValid1, setPhoneNumberIsValid1] = useState<boolean>(true);
  const [phoneNumberIsValid2, setPhoneNumberIsValid2] = useState<boolean>(true);

  const {
    formState: { errors },
    trigger,
    register,
    watch,
    setValue,
    setError,
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      [Field.FirstName1]: reference1?.firstName,
      [Field.LastName1]: reference1?.lastName,
      [Field.Relationship1]: reference1?.relationship,
      [Field.Phone1]: reference1?.phoneNumber,
      [Field.FirstName2]: reference2?.firstName,
      [Field.LastName2]: reference2?.lastName,
      [Field.Relationship2]: reference2?.relationship,
      [Field.Phone2]: reference2?.phoneNumber,
    },
  });
  const watcher = watch();

  useEffect(() => {
    register(Field.FirstName1, {
      required: getMessageForRequiredFields(Field.FirstName1),
    });
    register(Field.LastName1, {
      required: getMessageForRequiredFields(Field.LastName1),
    });
    register(Field.Relationship1, {
      required: getMessageForRequiredFields(Field.Relationship1),
    });
    register(Field.Phone1, {
      required: getMessageForRequiredFields(Field.Phone1),
    });
    register(Field.FirstName2, {
      required: getMessageForRequiredFields(Field.FirstName2),
    });
    register(Field.LastName2, {
      required: getMessageForRequiredFields(Field.LastName2),
    });
    register(Field.Relationship2, {
      required: getMessageForRequiredFields(Field.Relationship2),
    });
    register(Field.Phone2, {
      required: getMessageForRequiredFields(Field.Phone2),
    });
  }, [register, watcher]);

  const onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    setValue(event.target.name as Field, event.target.value.trim());
    trigger(event.target.name as Field);
  };

  const onChange = (event: React.FocusEvent<HTMLInputElement>) => {
    setValue(event.target.name as Field, event.target.value);
    trigger(event.target.name as Field);
  };

  const onNext = async () => {
    setIsLoading(true);

    try {
      await dispatchWithUnwrap(
        updateStudentLoanApplication({
          applicationId: applicationId!,
          applicationData: {
            reference1: {
              firstName: watcher[Field.FirstName1],
              lastName: watcher[Field.LastName1],
              relationship: watcher[Field.Relationship1],
              address: address1.line1,
              city: address1.city,
              state: address1.state,
              zip: address1.zip,
              phoneNumber: watcher[Field.Phone1],
            },
            reference2: {
              firstName: watcher[Field.FirstName2],
              lastName: watcher[Field.LastName2],
              relationship: watcher[Field.Relationship2],
              address: address2.line1,
              city: address2.city,
              state: address2.state,
              zip: address2.zip,
              phoneNumber: watcher[Field.Phone2],
            },
          },
        }),
      );
      analytics.track('Student Loan References Submitted');
      handleNext(StepsResult.Completed);
    } catch (error: any) {
      // error is being already handled
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <FormContainer
      title="References"
      subtitle="These are people that can be contacted by your loan servicer in case you miss a payment."
    >
      <h2>First Reference</h2>
      <Input
        label={FieldLabels.FirstName}
        placeholder={FieldLabels.FirstName}
        errorMessage={errors[Field.FirstName1]?.message}
        name={Field.FirstName1}
        onBlur={onBlur}
        onChange={onChange}
        value={watcher[Field.FirstName1]}
      />
      <Input
        label={FieldLabels.LastName}
        placeholder={FieldLabels.LastName}
        errorMessage={errors[Field.LastName1]?.message}
        name={Field.LastName1}
        onBlur={onBlur}
        onChange={onChange}
        value={watcher[Field.LastName1]}
      />
      <InputSelect
        label={FieldLabels.RelationshipLabel}
        options={RELATIONSHIP_OPTIONS}
        onChange={async (option) => {
          setValue(Field.Relationship1, option.value);
          trigger(Field.Relationship1 as Field);
          watcher[Field.Relationship1] = option.value as Relationship;
        }}
        value={watcher[Field.Relationship1]}
        placeholder={FieldLabels.RelationshipLabel}
        name={Field.Relationship1}
      />
      <AddressFields
        value={address1}
        onChange={(value, isValid) => {
          setAddress1(value);
          setIsAddress1Valid(isValid);
        }}
        hideLine2
      />
      <PhoneNumberInput
        label={FieldLabels.Phone}
        placeholder="000-000-0000"
        errorMessage={errors[Field.Phone1]?.message}
        name={Field.Phone1}
        onChange={(value) => {
          setValue(Field.Phone1, value);
          trigger(Field.Phone1);
        }}
        onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
          const valid = event.target.value ? isPossiblePhoneNumber(event.target.value as string, 'US') : false;
          setPhoneNumberIsValid1(valid);
          setError(Field.Phone1, {
            message: valid ? undefined : getMessageForInvalidFields(FieldLabels.Phone),
          });
          trigger(Field.Phone1);
        }}
        value={watcher[Field.Phone1]}
        maxLength={PHONE_NUMBER_LENGTH}
        country="US"
      />

      <h2>Second Reference</h2>
      <Input
        label={FieldLabels.FirstName}
        placeholder={FieldLabels.FirstName}
        errorMessage={errors[Field.FirstName2]?.message}
        name={Field.FirstName2}
        onBlur={onBlur}
        onChange={onChange}
        value={watcher[Field.FirstName2]}
      />
      <Input
        label={FieldLabels.LastName}
        placeholder={FieldLabels.LastName}
        errorMessage={errors[Field.LastName2]?.message}
        name={Field.LastName2}
        onBlur={onBlur}
        onChange={onChange}
        value={watcher[Field.LastName2]}
      />
      <InputSelect
        label={FieldLabels.RelationshipLabel}
        options={RELATIONSHIP_OPTIONS}
        onChange={async (option) => {
          setValue(Field.Relationship2, option.value);
          trigger(Field.Relationship2 as Field);
          watcher[Field.Relationship2] = option.value as Relationship;
        }}
        value={watcher[Field.Relationship2]}
        placeholder={FieldLabels.RelationshipLabel}
        name={Field.Relationship2}
      />
      <AddressFields
        value={address2}
        onChange={(value, isValid) => {
          setAddress2(value);
          setIsAddress2Valid(isValid);
        }}
        hideLine2
      />
      <PhoneNumberInput
        label={FieldLabels.Phone}
        placeholder="000-000-0000"
        errorMessage={errors[Field.Phone2]?.message}
        name={Field.Phone2}
        onChange={(value) => {
          setValue(Field.Phone2, value);
          trigger(Field.Phone1);
        }}
        onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
          const valid = event.target.value ? isPossiblePhoneNumber(event.target.value as string, 'US') : false;
          setPhoneNumberIsValid2(valid);
          setError(Field.Phone2, {
            message: valid ? undefined : getMessageForInvalidFields(FieldLabels.Phone),
          });
          trigger(Field.Phone2);
        }}
        value={watcher[Field.Phone2]}
        maxLength={PHONE_NUMBER_LENGTH}
        country="US"
      />

      <Button
        className={styles.button}
        onClick={onNext}
        isLoading={isLoading}
        disabled={
          !(
            Object.keys(errors).length === 0 &&
            phoneNumberIsValid1 &&
            phoneNumberIsValid2 &&
            isAddress1Valid &&
            isAddress2Valid &&
            Boolean(
              address1.line1 &&
                address1.city &&
                address1.state &&
                address1.zip &&
                address2.line1 &&
                address2.city &&
                address2.state &&
                address2.zip &&
                watcher[Field.FirstName1] &&
                watcher[Field.FirstName2] &&
                watcher[Field.LastName1] &&
                watcher[Field.LastName2] &&
                watcher[Field.Relationship1] &&
                watcher[Field.Relationship2] &&
                watcher[Field.Phone1] &&
                watcher[Field.Phone2],
            )
          )
        }
      >
        Save and Continue
      </Button>
    </FormContainer>
  );
};

const getAddress = (reference: PersonalReference | undefined): Address => ({
  line1: reference?.address,
  city: reference?.city,
  state: reference?.state,
  zip: reference?.zip,
});

const isCompleteAddress = (address: Address): boolean =>
  Boolean(address.line1 && address.city && address.state && address.zip);

export default References;
