import React, { Suspense } from 'react';
import { useSelector } from 'react-redux';
import ReactLoading from 'react-loading';
import { RootState } from 'handlers';
import { uploadDocuments } from 'thunks';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import FormContainer from 'components/LoanForm/FormContainer';
import Button from 'components/Button';
import DropzoneWidget, { useFiles } from 'components/DropzoneWidget';
import { getApplicationData } from 'selectors/getApplicationData';
import { DigifiDocumentType } from 'api/DocumentsApi';
import { StepComponent } from 'components/Steps/stepUtils';
import { getStudentLoanInnerApplicationData } from 'selectors/getStudentLoanApplication';
import { StepsResult } from 'enums/FlowNextResults';
import { ReactComponent as CheckIcon } from 'images/green-check-rounded.svg';
import { getPartnerDataFromEmployerName } from 'enums/PartnerName';

import styles from './UploadIncomeVerification.module.scss';

const FilesPreview = React.lazy(() => import('components/FilesPreview'));

const renameFiles = (cb: (files: File[]) => void, filePrefix?: string) => (files: File[]) => {
  const prefix = filePrefix ? `${filePrefix} ` : '';
  return cb(files.map((file) => new File([file], `${prefix}${file.name}`, { type: file.type })));
};

const UploadIncomeVerification = ({ handleNext }: StepComponent) => {
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const { handleAddFiles, files, handleRemoveFiles } = useFiles();

  const {
    applicationId,
    applicationData: { applyProgress },
  } = useSelector(getStudentLoanInnerApplicationData);

  const { isApplicationStatusUpdating } = useSelector(getApplicationData);
  const { isLoading } = useSelector((state: RootState) => state.filesUpload);
  const {
    applicationData: { employerName },
  } = useSelector(getStudentLoanInnerApplicationData);

  const { downloadPayrollInstructionsLink, shortName } = getPartnerDataFromEmployerName(employerName) ?? {};
  const employerInstructions = downloadPayrollInstructionsLink ? (
    <p>
      See{' '}
      <a href={downloadPayrollInstructionsLink} target="_blank" rel="noreferrer">
        instructions
      </a>{' '}
      on how to download a W2 at {shortName}.
    </p>
  ) : undefined;

  const handleContinue = async () => {
    const filesForUpload: File[] = files.map((item) => item.file);

    analytics.track('Upload Income Verification in Progress');
    await dispatchWithUnwrap(
      uploadDocuments({
        applicationId: applicationId!,
        files: filesForUpload,
        fileType: DigifiDocumentType.W2OrPaystub,
      }),
    );

    handleNext(StepsResult.Completed);
  };

  return (
    <FormContainer
      title="Income Verification"
      subtitle="Upload either your most recent W2 or your last 2 paystubs."
      className={styles.formContainer}
    >
      {employerInstructions}
      <DropzoneWidget handleFiles={renameFiles(handleAddFiles, 'Paystub')} extensions={['application/pdf']} />
      <ul className={styles.filesRequirementsList}>
        <li>Only PDF files are supported</li>
        <li>Maximum file size 10MB</li>
      </ul>
      {files.length ? (
        <Suspense fallback={<ReactLoading type="spin" color="#fff" />}>
          <FilesPreview files={files} handleRemove={handleRemoveFiles} />
        </Suspense>
      ) : (
        <></>
      )}

      {applyProgress?.incomeVerification && (
        <>
          <div className={styles.uploaded}>
            <CheckIcon className={styles.checkIcon} />
            <p className={styles.verifiedLabel}>Income documents uploaded</p>
          </div>

          {!files.length && <Button onClick={handleNext}>Next</Button>}
        </>
      )}

      {Boolean(!applyProgress?.incomeVerification || files.length) && (
        <Button
          disabled={!files.length}
          className={styles.button}
          onClick={handleContinue}
          isLoading={isLoading || isApplicationStatusUpdating}
        >
          Save and Continue
        </Button>
      )}
    </FormContainer>
  );
};

export default UploadIncomeVerification;
